import React from 'react';
import Box from '@material-ui/core/Box';

import Layout from '../components/layout';
import Addons from '../components/add-ons/Addons';
import SEO from '../components/seo';

import addons from '../constants/add-ons/add-ons';

const AddonsPage = () => {
	return (
		<Layout>
			<SEO title="Add-ons" description="Add-ons available for our dry stack marina and boat club app and software." />
			<Box pt={8} bgcolor="secondary.dark" color="primary.main">
				<Addons addons={addons} isAngledBottom={false} isAngledTop={false} />
			</Box>
		</Layout>
	);
};

export default AddonsPage;
