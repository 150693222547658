import React from 'react';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconAirplay from '@material-ui/icons/Airplay';
import IconCardMembership from '@material-ui/icons/CardMembership';
import IconLiveTv from '@material-ui/icons/LiveTv';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import AngledSection from '../layout/AngledSection';
import FadeInUp from '../transitions/FadeInUp';
import theme from '../../constants/theme/theme';
import ThemedButton from '../button/ThemedButton';

const useStyles = makeStyles({
    avatar: {
        height: '64px',
        width: '64px',
    },
    card: {
        height: '100%',
    },
});

const Addons = ({ addons = [], isAngledBottom, isAngledTop, paletteColor = 'primary' }) => {
    const classes = useStyles();
    const backgroundStyles = { backgroundColor: theme.palette[paletteColor].dark };

    return (
        <FadeInUp>
            <AngledSection
                bottomColor={theme.palette[paletteColor].light}
                isAngledBottom={isAngledBottom}
                isAngledTop={isAngledTop}
            >
                <Box pb={12} pt={12} bgcolor={`${paletteColor}.main`}>
                    <Container align="center">
                        <Box pb={4}>
                            <Typography align="center" variant="h3" color="secondary" gutterBottom>
                                Add-ons
                            </Typography>
                            <Box color="secondary.dark">
                                <Typography color="inherit" paragraph>
                                    Enhance your SpeedyDock experience with our suite of available add-ons.
                                </Typography>
                            </Box>
                        </Box>
                    </Container>
                    <Container>
                        <Grid container spacing={3} justify="center">
                            {
                                addons.map((addon, i) => {
                                    const Icon = [IconAirplay, IconLiveTv, IconCardMembership][i];
                                    return (
                                        <Grid key={addon.name} item xs={12} sm={12} md={4}>
                                            <Card className={classes.card}>
                                                <Box display="flex" justifyContent="space-between" flexDirection="column" className={classes.card}>
                                                    <CardContent>
                                                        <Box color={`${paletteColor}.dark`} display="flex" alignItems="center" justifyContent="space-between">
                                                            <Avatar className={classes.avatar} variant="circular" style={backgroundStyles}>
                                                                <Icon color="secondary" fontSize="large" />
                                                            </Avatar>
                                                            {
                                                                (addon.isNew || addon.isComingSoon) && (
                                                                    <Chip
                                                                        color={addon.isNew ? 'primary' : undefined}
                                                                        label={addon.isNew ? 'New' : 'Coming Soon'}
                                                                        style={addon.isNew ? backgroundStyles : {}}
                                                                    />
                                                                )
                                                            }
                                                        </Box>
                                                        <Box color={`${paletteColor}.dark`} pt={3} pb={2}>
                                                            <Typography color="inherit" variant="h5" component="h3" gutterBottom>
                                                                {addon.name}
                                                            </Typography>
                                                        </Box>
                                                        <Typography variant="body1">
                                                            {addon.description}
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions>
                                                        <ThemedButton
                                                            to={`/add-on/${addon.urlSlug}`}
                                                            color="primary"
                                                        >
                                                            Learn More &rarr;
                                                        </ThemedButton>
                                                    </CardActions>
                                                </Box>
                                            </Card>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Container>
                </Box>
            </AngledSection>
        </FadeInUp>
    );
};

export default Addons;
